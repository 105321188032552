<template>
  <v-dialog
    :value="value"
    max-width="850"
    @click:outside="$emit('close')">
    <v-card tile flat>
      <v-toolbar flat class="mb-4 pt-2">
        <v-toolbar-title>
          <v-card-title>Shuttle Promotion</v-card-title>
          <v-card-subtitle>Select parties to include on shuttle</v-card-subtitle>
        </v-toolbar-title>
        <v-text-field
          v-model="search"
          label="Search parties"
          prepend-inner-icon="mdi-magnify"
          outlined
          dense
          append-icon="mdi-window-close"
          class="ml-2"
          hide-details
          @click:append="search = ''">
        </v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          small
          text
          @click="$emit('close')">
          Cancel
        </v-btn>
        <v-btn
          small
          color="primary"
          :loading="saving"
          :disabled="saving || (partiesOnShuttle.length === 0 && !includeAll)"
          @click="saveShuttle">
          Confirm
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
       <v-container fluid class="ma-0 pa-0">
        <v-data-table
          :height="tableSize"
          :headers="headers"
          :items.sync="parties"
          :loading="loading"
          :server-items-length="serverItemsLength"
          :footer-props="{'items-per-page-options': pageSizes}"
          loading-text="Loading... please wait"
          :items-per-page.sync="pageSize"
          no-data-text="No valid shuttle parties found for this promotion"
          :page.sync="page"
          fixed-header
          dense>
          <template v-slot:[`header.included`]>
            <v-simple-checkbox
              color="primary"
              :disabled="parties.length === 0"
              v-model="includeAll"
              dense
              :ripple="false">
            </v-simple-checkbox>
          </template>
          <template v-slot:[`item.included`]="{ item }">
            <v-simple-checkbox
              v-if="item"
              color="primary"
              v-model="item.included"
              :disabled="includeAll"
              @input="partySelected($event, item)"
              dense
              :ripple="false">
            </v-simple-checkbox>
          </template>
        </v-data-table>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import Bottleneck from 'bottleneck'
import Promos from '@/axios/promotion-endpoint'
import PartyRel from '@/axios/party-relationship-endpoint'
import Es from '@/axios/es'
import { displayAlert } from '@/mixins/alert'
import { buildElasticQuery } from '@/mixins/es_querybuilder'
import { fullWidthTable } from '@/mixins/table'
import { utils } from '@/mixins/utils'
export default {
  data () {
    return {
      from: 0,
      parties: [],
      relatedPartyIds: [],
      partiesOnShuttle: [],
      relatedPartySources: [],
      saving: false,
      includeAll: false,
      search: '',
      headers: [
        {},
        { text: 'Party', sortable: false, value: 'name'},
        { text: 'Party Type', sortable: false, value: 'party_type.name'},
        { text: '', sortable: false, align: 'center', value: 'included'}
      ]
    }
  },
  name: 'ShuttleModal',
  mixins: [displayAlert, buildElasticQuery, fullWidthTable, utils],
  props: {
    value: Boolean,
    promoPartyIds: Array,
    partyItemSources: Array
  },
  watch: {
    includeAll: {
      handler (newValue) {
        this.parties = this.parties.map(party => {
          party.included = newValue
          return party
        })
        if (newValue === false) {
          this.partiesOnShuttle = []
        }
      }
    }
  },
  async created () {
    this.nonTableHeight = 450
    if (this.promoPartyIds.length > 0) {
      await this.initShuttleParties()
    }
    this.tableChanged = this.tableChange(this.getShuttleParties)
    this.submitSearch = this.submitSearch(this.getShuttleParties)
  },
  computed: {
    shuttleChildrenPartyTypes () {
      return ['AD_GROUP']
    }
  },
  methods: {
    async initShuttleParties () {
      this.loading = true
      try {
        // iterate over promo parties, get their related party ids, create lookup
        // to use for item_sources in shuttle post request payload
        const promises = this.promoPartyIds.map(parentPartyId => {
          return this.getDownstreamPartyIds(parentPartyId).then(relatedIds => {
            return (relatedIds.length > 0)
              ? this.mapPartiesToParentSources(parentPartyId, relatedIds)
              : []
          })
        })
        const { fulfilled, rejected } = await this.getAllSettled(promises, true)
        if (rejected.length > 0) throw rejected

        const relatedPartyIds = fulfilled.flatMap(result => result?.party_id || [])
        this.relatedPartyIds = Array.from(new Set(relatedPartyIds))
        this.relatedPartySources = this.buildRelatedPartySourceLookup(fulfilled)
        await this.getShuttleParties()
      } catch (err) {
        this.loading = false
        this.handleError(err)
      }
    },
    mapPartiesToParentSources (parentPartyId, relatedPartyIds) {
      // create item source lookup for related parties based on parent
      const parentPartySources = this.getPartySources(parentPartyId, this.partyItemSources)
      return relatedPartyIds.map(relatedId => {
        return { party_id: relatedId, sources: parentPartySources }
      })
    },
    buildRelatedPartySourceLookup (results) {
      if (results.length === 0) return results
      return this.relatedPartyIds.map(relatedId => {
        // a single downstream party could be related to multiple parties on the promo,
        // so merge sources together for the final lookup
        const matches = results.filter(obj => obj.party_id === relatedId)
        const sources = matches.flatMap(match => match.sources)
        return { party_id: relatedId, sources }
      })
    },
    async getDownstreamPartyIds (party_id) {
      const downstreamRels = await PartyRel.getDownstreamRelationships({ party_id })
      return downstreamRels?.data?.related || []
    },
    async getShuttleParties () {
      this.loading = true
      if (this.relatedPartyIds.length === 0) {
        return this.loading = false
      }
      const payload = this.buildShuttlePartiesPayload()
      try {
        let parties = []
        let total = 0
        const res = await Es.post('CUSTOMER', '/_search?rest_total_hits_as_int', payload)
        if (res?.hits?.hits?.length > 0) {
          total = res.hits.total
          parties = res.hits.hits.map(result => {
            const party = result._source
            const includedOnShuttle = this.partiesOnShuttle.some(p => p?.id === party.id)
            party.included = this.includeAll || includedOnShuttle
            return party
          })
        }
        this.serverItemsLength = total
        this.parties = parties
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    async saveShuttle () {
      this.saving = true
      const promoId = this.$route.query.id
      const limiter = this.initLimiter()
      try {
        const partiesOnShuttle = this.includeAll
          ? await this.getAllShuttleParties()
          : this.partiesOnShuttle

        const wrapFn = (...args) => Promos.shuttle(...args)
        const throttledShuttle = limiter.wrap(wrapFn)
        const promises = partiesOnShuttle.map(party => {
          const sources = this.getPartySources(party.id, this.relatedPartySources)
          return throttledShuttle(promoId, party.id, party.name, sources)
        })
        const { rejected } = await this.getAllSettled(promises, true)
        if (rejected.length === 0) {
          this.emitAlert(true, 'success', 'Promotion shuttled successfully', [], true)
          this.$emit('close')
        } else {
          throw rejected
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.saving = false
        // allow limiter to be garbage collected
        limiter.disconnect()
      }
    },
    getPartySources (partyId, partySourceLookup) {
      const match = partySourceLookup.find(obj => obj.party_id === partyId)
      return match?.sources || []
    },
    initLimiter () {
      const limiter = new Bottleneck({
        reservoir: 50,
        reservoirRefreshAmount: 50,
        reservoirRefreshInterval: 1000,
        maxConcurrent: 50,
        minTime: 5
      })
      limiter.on('error', (err) => {
        return this.handleError(err)
      })
      return limiter
    },
    async getAllShuttleParties () {
      if (this.parties.length === this.serverItemsLength) {
        return this.parties
      }
      const payload = this.buildShuttlePartiesPayload()
      payload.body.from = 0
      payload.body.size = this.serverItemsLength
      const res = await Es.post('CUSTOMER', '/_search?rest_total_hits_as_int', payload)
      return res.hits.hits.map(r => r._source)
    },
    buildShuttlePartiesPayload () {
      const query = this.buildElasticQuery('CUSTOMER')
      query.bool.filter.push(
        { terms: {'id.keyword': [...this.relatedPartyIds] }},
        { terms: {'party_type.constant.keyword': [...this.shuttleChildrenPartyTypes] }}
      )
      const payload = {
        body: {
          from: this.from,
          size: this.pageSize,
          query,
          sort: [
            {'party_type.constant.keyword': {order: 'asc'}},
            {'name.keyword': {order: 'asc'}}
          ]
        }
      }
      return payload
    },
    partySelected (value, party) {
      if (value === true) {
        this.partiesOnShuttle.push(party)
      } else {
        const index = this.partiesOnShuttle.findIndex(p => p.id === party.id)
        this.partiesOnShuttle.splice(index, 1)
      }
    }
  }
}
</script>